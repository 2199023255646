@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap);
/* body {
  background: -moz-radial-gradient(
    circle,
    rgba(28, 41, 59, 1) 0%,
    rgba(8, 15, 25, 1) 55%
  ) !important;
  background: -webkit-radial-gradient(
    circle,
    rgba(28, 41, 59, 1) 0%,
    rgba(8, 15, 25, 1) 55%
  ) !important;
  background: radial-gradient(
    circle,
    rgba(28, 41, 59, 1) 0%,
    rgba(8, 15, 25, 1) 55%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1C293B",endColorstr="#080F19",GradientType=1) !important;
} */

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background: #efa618;
  border-radius: 10px;
}

